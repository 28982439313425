import React from 'react';
import {useControllerProps} from '../../../../../domain/controllers/ControllerContext';
import {CartItem} from './CartItem/CartItem';

export enum CartItemsDataHook {
  'root' = 'CartItemsDataHook.root',
  'item' = 'CartItemsDataHook.item',
}

export function CartItems() {
  const {cart} = useControllerProps().cartStore;

  return (
    <div data-hook={CartItemsDataHook.root}>
      <ul>
        {cart.items.map((item) => (
          <li
            key={`cart-item-${item.cartItemId}`}
            data-hook={CartItemsDataHook.item}
            data-wix-line-item-id={item.cartItemId}>
            <CartItem item={item} />
          </li>
        ))}
      </ul>
    </div>
  );
}
