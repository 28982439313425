import React, {useEffect} from 'react';
import cx from 'classnames';
import s from './CartApp.scss';
import {CartBody} from './partials/CartBody';
import {EmptyState} from '../Components/EmptyState/EmptyState';
import {useControllerProps} from '../../../../domain/controllers/ControllerContext';
import {withResizeObserver} from './HOC/withResizeObserver';
import {withSentryErrorBoundary} from '@wix/native-components-infra';
import {CART_DSN} from '@wix/wixstores-client-storefront-sdk';
import {useExperiments} from '@wix/yoshi-flow-editor';
import {SPECS} from '../../../../domain/specs';

export enum CartAppDataHook {
  root = 'CartAppDataHook.root',
}

const CartApp = () => {
  const {isResponsive} = useControllerProps();
  const {shouldRenderEmptyState} = useControllerProps().cartStore;
  const {handlePaymentMethodError} = useControllerProps().cashierExpressStore;
  const {experiments} = useExperiments();
  const ShouldChangeMaxViewportMobile = experiments.enabled(SPECS.ChangeMaxViewportMobile);
  const viewerVersion = isResponsive ? 'viewer_x' : 'viewer_classic';
  const classNames = cx(
    /* istanbul ignore next */ ShouldChangeMaxViewportMobile ? s.main : s.mainViewport,
    s[viewerVersion]
  );

  useEffect(() => {
    handlePaymentMethodError();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <div className={s.container}>
      <main data-hook={CartAppDataHook.root} className={classNames}>
        {shouldRenderEmptyState ? <EmptyState /> : <CartBody />}
      </main>
    </div>
  );
};

// eslint-disable-next-line import/no-default-export
export default withSentryErrorBoundary(withResizeObserver(CartApp), {
  dsn: CART_DSN,
  config: {environment: 'Native Component'},
});
