import React from 'react';
import {OrderSummaryDataHook} from '../../OrderSummary';
import {useControllerProps} from '../../../../../../../domain/controllers/ControllerContext';
import {useExperiments, useTranslation} from '@wix/yoshi-flow-editor';
import s from './ShippingDestination.scss';
import {SPECS} from '../../../../../../../domain/specs';

type ShippingDestinationProps = {
  dataHook: string;
};

export const ShippingDestination = ({dataHook}: ShippingDestinationProps) => {
  const {t} = useTranslation();
  const {hasShippingDestination, handleChangeRegion, shouldShowShipping, shouldShowTax} =
    useControllerProps().orderStore;
  const {cart} = useControllerProps().cartStore;
  const {experiments} = useExperiments();
  const ShouldChangeMaxViewportMobile = experiments.enabled(SPECS.ChangeMaxViewportMobile);

  const getChooseRegionTranslationKey = (): string => {
    if (shouldShowShipping && shouldShowTax) {
      return 'cart.shipping_destination.shipping_and_tax.choose_region';
    }
    if (shouldShowShipping && !shouldShowTax) {
      return 'cart.shipping_destination.choose_region';
    }
    return 'cart.shipping_destination.tax.choose_region';
  };

  const getShippingRegion = (): string => {
    const {destination} = cart;

    let shippingLocation = t('cart.fallback_estimated_shipping_address');

    if (destination.countryName) {
      shippingLocation = destination.countryName;

      if (destination.subdivisionName) {
        shippingLocation = t('cart.estimated_shipping_address', {
          country: destination.countryName,
          subdivision: destination.subdivisionName,
        });
      }
    }

    return shippingLocation;
  };

  return (
    <div
      className={/* istanbul ignore next */ ShouldChangeMaxViewportMobile ? s.location : s.locationViewport}
      data-hook={dataHook}>
      <button
        data-hook={OrderSummaryDataHook.ShippingDestinationChangeRegion}
        type="button"
        onClick={() => handleChangeRegion()}
        aria-haspopup={true}
        {...(hasShippingDestination && {'aria-label': t('cart.sr_change_destination')})}>
        {hasShippingDestination ? getShippingRegion() : t(getChooseRegionTranslationKey())}
      </button>
    </div>
  );
};
