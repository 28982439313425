import React from 'react';
import s from './PayNowLater.scss';
import classnames from 'classnames';
import {useControllerProps} from '../../../../../../domain/controllers/ControllerContext';
import {OrderSummaryDataHook} from '../OrderSummary';
import {useExperiments, useTranslation} from '@wix/yoshi-flow-editor';
import {SPECS} from '../../../../../../domain/specs';

export const PayNowLater = () => {
  const {
    totals: {payLater, formattedPayNow, formattedPayLater},
  } = useControllerProps().cartStore.cart;
  const {t} = useTranslation();
  const {experiments} = useExperiments();
  const ShouldChangeMaxViewportMobile = experiments.enabled(SPECS.ChangeMaxViewportMobile);

  return (
    payLater > 0 && (
      <div
        data-hook={OrderSummaryDataHook.PayNowLaterRoot}
        className={classnames(
          /* istanbul ignore next */ ShouldChangeMaxViewportMobile ? s.topDivider : s.topDividerViewport,
          s.root
        )}>
        <dl>
          <dt>
            <span data-hook={OrderSummaryDataHook.PayNowTitle} className={s.payNow}>
              {t('cart.orderSummary.amountDueNow.label')}
            </span>
          </dt>
          <dd
            data-hook={OrderSummaryDataHook.PayNowValue}
            data-wix-payNow-value={OrderSummaryDataHook.PayNowValue}
            className={s.payNow}>
            {formattedPayNow}
          </dd>
        </dl>
        <dl>
          <dt>
            <span data-hook={OrderSummaryDataHook.PayLaterTitle}>{t('cart.orderSummary.amountDueLater.label')}</span>
          </dt>
          <dd
            data-hook={OrderSummaryDataHook.PayLaterValue}
            data-wix-payLater-value={OrderSummaryDataHook.PayLaterValue}>
            {formattedPayLater}
          </dd>
        </dl>
      </div>
    )
  );
};
