import React from 'react';
import {CheckoutButton} from './CheckoutButton/CheckoutButton';
import {useControllerProps} from '../../../../../domain/controllers/ControllerContext';
import s from './CheckoutButtons.scss';
import {useEnvironment, useExperiments} from '@wix/yoshi-flow-editor';
import {ExpressCheckoutButtons} from '@wix/ecom-platform-express-checkout-buttons';
import {SPECS} from '../../../../../domain/specs';

export enum CheckoutButtonsDataHook {
  Default = 'CheckoutButtons.default',
  Top = 'CheckoutButtons.topView',
  Wrapper = 'CheckoutButtons.Wrapper',
}

interface CheckoutButtonsProps {
  isOnTop?: boolean;
}

export function CheckoutButtons(props: CheckoutButtonsProps) {
  const {shouldShowDynamicPaymentOptions, dynamicPaymentOptionsProps} = useControllerProps().cashierExpressStore;
  const {isCheckoutRegularFlowEnabled, cart, shouldShowExpressCheckout} = useControllerProps().cartStore;
  const {isCheckoutButtonDisabled} = useControllerProps().navigationStore;
  const {isLoading} = useControllerProps();
  const {isSSR} = useEnvironment();
  const {isOnTop} = props;
  const {total} = cart.totals;
  const dataHook = isOnTop ? CheckoutButtonsDataHook.Top : CheckoutButtonsDataHook.Default;
  const shouldRequestShipping = dynamicPaymentOptionsProps?.shouldRequestShipping ?? false;
  const canCheckout = dynamicPaymentOptionsProps?.canCheckout ?? false;
  const {experiments} = useExperiments();
  const ShouldChangeMaxViewportMobile = experiments.enabled(SPECS.ChangeMaxViewportMobile);

  return (
    <div
      data-hook={dataHook}
      className={
        /* istanbul ignore next */ ShouldChangeMaxViewportMobile ? s.checkoutButtons : s.checkoutButtonsViewport
      }>
      {isCheckoutRegularFlowEnabled && <CheckoutButton disabled={isCheckoutButtonDisabled || isLoading} />}
      {shouldShowDynamicPaymentOptions && !isSSR && shouldShowExpressCheckout ? (
        <div className={s.expressButtonsContainer}>
          <ExpressCheckoutButtons
            layout={'vertical'}
            isShippable={shouldRequestShipping}
            estimatedTotal={total.toString()}
            canStartPayment={canCheckout}
            theme={dynamicPaymentOptionsProps?.getButtonTheme}
            buttonStyle={dynamicPaymentOptionsProps?.buttonStyle}
            dataHook={CheckoutButtonsDataHook.Wrapper}
          />
        </div>
      ) : null}
    </div>
  );
}
