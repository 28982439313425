import React, {useMemo, useState} from 'react';
import {useExperiments, useTranslation} from '@wix/yoshi-flow-editor';
import {useSettings} from '@wix/tpa-settings/react';
import {debounce} from '../../../../../domain/lib/debounce';
import settingsParams from '../../../settingsParams';
import {useControllerProps} from '../../../../../domain/controllers/ControllerContext';
import {DocumentIcon} from '../Icons/DocumentIcon';
import {TextArea} from 'wix-ui-tpa';
import s from './BuyerNote.scss';
import {classes} from './BuyerNote.st.css';
import {SPECS} from '../../../../../domain/specs';

export enum BuyerNoteDataHook {
  Root = 'BuyerNoteDataHook.root',
  Button = 'BuyerNoteDataHook.button',
  Body = 'BuyerNoteDataHook.body',
}

const AddNoteButton = ({handleClick, caption, isOpen}: {handleClick: () => void; caption: string; isOpen: boolean}) => (
  <button
    className={s.button}
    type="button"
    data-hook={BuyerNoteDataHook.Button}
    onClick={handleClick}
    aria-expanded={isOpen}>
    <DocumentIcon className={s.icon} />
    {caption}
  </button>
);

const AddNoteBody = ({
  placeholder,
  handleChange,
  buyerNote,
}: {
  placeholder: string;
  handleChange: any;
  buyerNote: string;
}) => {
  const {t} = useTranslation();

  return (
    <div className={s.body}>
      <TextArea
        className={classes.textArea}
        data-hook={BuyerNoteDataHook.Body}
        placeholder={placeholder}
        autoFocus={true}
        maxLength={250}
        value={buyerNote || ''}
        onChange={handleChange}
        ariaLabel={t('cart.buyer_note_placeholder')}
      />
    </div>
  );
};

export const BuyerNote = () => {
  const {get: getSettings} = useSettings();
  const {updateBuyerNote, sendAddNoteBi} = useControllerProps().cartStore;
  const [buyerNote, setBuyerNote] = useState<string>(useControllerProps().cartStore.cart.buyerNote);
  const [isOpen, setIsOpen] = useState<boolean>(Boolean(buyerNote));
  const debouncedUpdateBuyerNote = useMemo(() => debounce(updateBuyerNote), [updateBuyerNote]);
  const {experiments} = useExperiments();
  const ShouldChangeMaxViewportMobile = experiments.enabled(SPECS.ChangeMaxViewportMobile);

  const handleChange = ({target: {value}}: React.ChangeEvent<HTMLTextAreaElement>) => {
    setBuyerNote(value);
    return debouncedUpdateBuyerNote(value);
  };

  const handleOpen = () => {
    !isOpen && sendAddNoteBi();
    setIsOpen(buyerNote ? true : !isOpen);
  };

  return (
    <div
      className={/* istanbul ignore next */ ShouldChangeMaxViewportMobile ? s.note : s.noteViewport}
      data-hook={BuyerNoteDataHook.Root}>
      <AddNoteButton
        isOpen={isOpen}
        handleClick={handleOpen}
        caption={getSettings(settingsParams.CART_ADD_NOTE_LINK) as string}
      />
      {isOpen && (
        <AddNoteBody
          placeholder={getSettings(settingsParams.CART_INSTRUCTIONS_TEXT) as string}
          handleChange={handleChange}
          buyerNote={buyerNote}
        />
      )}
    </div>
  );
};
